import { IFrame } from './IFrame';

export default function _970x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={3} width={"970"} height={"250"} title={"_970x250-Banner"} id={"_970x250-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Omni_Animated_Retargeting/published/Omni_Animated_Retargeting_new_970x250/index.html'}/>
        </>
    );
}
