import React, {useState} from 'react'
import BannerContainer from './BannerContainer';
import FormFields from '../../../../elements/FormFields';
import OmniDataService from '../../../../../services/omniAxios.js'
import SaveSheet from '../../../../elements/saveSheet';

export default function Design(props) {
  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle= "New Retargeting";
  const [formData, setFormData] = useState(false);
  const themeColumnName = 'Hotel Code'

  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));
  const [key160x600, setkey160x600] = useState(Math.random().toString(16).slice(2));
  const saving = true;

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
    setkey160x600(key160x600 + 1);
  }

  function formatFormData(data) {
    return {
      name: "",
      Logo_Midnight: data?.supplementary_row?.['Logo Midnight'] || "",
      Logo_Cream: data?.supplementary_row?.['Logo Cream'] || "",
      Logo_Mobile: data?.supplementary_row?.['Logo Mobile'] || "",
      Hotel_Code: data?.supplementary_row?.['Hotel Code'] || "",
      Exit_URL: data?.row?.['Exit URL'] || "",
      Headline: data?.row?.['Headline'] || "",
      Subheadline: data?.row?.['Subheadline'] || "",
      CTA: data?.row?.['CTA'] || "",
      Image_Vertical: data?.row?.['Image Vertical'] || "",
      Image_Horizontal: data?.row?.['Image Horizontal'] || "",
      Logo_URL: data?.row?.['Logo URL'] || "",
      design: sheetTitle
    }
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesignRetargetingQ42024Sheets();
  }

  const note = "*You cannot save a new color theme here. You must save a new hotel code or change the hotel code / color mapping in the feed sheet."


  return (
          <div>
            <FormFields themeColumnName={themeColumnName} refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} note={note}/>
            {bannerActive &&
                <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
            {formData && bannerActive &&
              <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} setkey160x600={setkey160x600} key160x600={key160x600} {...formData}/>
            }
          </div>
  );
}

