import { IFrame } from './IFrame';

export default function _728x90(props){

    return (
        <>
            <IFrame {...props} iframenumber={1} width={"728"} height={"90"} title={"_728x90-Banner"} id={"_728x90-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Omni_Animated_Retargeting/published/Omni_Animated_Retargeting_new_728x90/index.html'}/>
        </>
    );
}
