import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios'

import PreviewSheetListDesignMomentive1 from './components/elements/previewSheetLists/surveymonkey/previewSheetLists_Momentive1.js'
import PreviewSheetDesignMomentive1 from './components/elements/previewSheets/surveymonkey/previewSheet_Momentive1.js'
import DesignMomentive1Form from './components/clients/surveymonkey/Momentive1/elements/designForm.js'
import PreviewSheetListDesignProspecting from './components/elements/previewSheetLists/omni/previewSheetLists_Prospecting.js'
import PreviewSheetDesignProspecting from './components/elements/previewSheets/omni/previewSheet_Prospecting.js'
import DesignProspectingForm from './components/clients/omni/Prospecting/elements/designForm.js'
import PreviewSheetListDesignRetargeting from './components/elements/previewSheetLists/omni/previewSheetLists_Retargeting.js'
import PreviewSheetDesignRetargeting from './components/elements/previewSheets/omni/previewSheet_Retargeting.js'
import DesignRetargetingForm from './components/clients/omni/Retargeting/elements/designForm.js'
import PreviewSheetListDesignNothingBundtCakesQ22023 from './components/elements/previewSheetLists/nothingbundtcakes/previewSheetLists_NothingBundtCakesQ22023.js'
import PreviewSheetDesignNothingBundtCakesQ22023 from './components/elements/previewSheets/nothingbundtcakes/previewSheet_NothingBundtCakesQ22023.js'
import DesignNothingBundtCakesQ22023Form from './components/clients/nothingbundtcakes/NothingBundtCakesQ22023/elements/designForm.js'
import PreviewSheetListDesignSMDesign9 from './components/elements/previewSheetLists/surveymonkey/previewSheetLists_SMDesign9.js'
import PreviewSheetDesignSMDesign9 from './components/elements/previewSheets/surveymonkey/previewSheet_SMDesign9.js'
import DesignSMDesign9Form from './components/clients/surveymonkey/SMDesign9/elements/designForm.js'
import PreviewSheetListDesignSMDesign8 from './components/elements/previewSheetLists/surveymonkey/previewSheetLists_SMDesign8.js'
import PreviewSheetDesignSMDesign8 from './components/elements/previewSheets/surveymonkey/previewSheet_SMDesign8'
import DesignSMDesign8Form from './components/clients/surveymonkey/SMDesign8/elements/designForm.js'
import PreviewSheetListDesignTCS2022 from './components/elements/previewSheetLists/thecontainerstore/previewSheetLists_TCS2022'
import PreviewSheetDesignTCS2022 from './components/elements/previewSheets/thecontainerstore/previewSheet_TCS2022'
import DesignTCS2022Form from './components/clients/thecontainerstore/TCS2022/elements/designForm.js'
import PreviewSheetListDesignGenericBanners from './components/elements/previewSheetLists/generic/previewSheetLists_Generic_Banners.js'
import PreviewSheetDesignGenericBanners from './components/elements/previewSheets/generic/previewSheet_Generic_Banners.js'
import DesignGenericBannersForm from './components/clients/generic/Generic_Banners/elements/designForm.js'
import PreviewSheetListDesign8 from './components/elements/previewSheetLists/omni/previewSheetList_design8.js'
import PreviewSheetListDesign1 from './components/elements/previewSheetLists/omni/previewSheetList_design1.js'
import PreviewSheetListDesign4 from './components/elements/previewSheetLists/omni/previewSheetList_design4.js'
import PreviewSheetDesign8 from './components/elements/previewSheets/omni/previewSheet_design8.js'
import PreviewSheetDesign1 from './components/elements/previewSheets/omni/previewSheet_design1.js'
import PreviewSheetDesign4 from './components/elements/previewSheets/omni/previewSheet_design4.js'
import PreviewSheetListDesignProspectingQ42024 from './components/elements/previewSheetLists/omni/previewSheetLists_ProspectingQ42024.js'
import PreviewSheetDesignProspectingQ42024 from './components/elements/previewSheets/omni/previewSheet_ProspectingQ42024.js'
import DesignProspectingQ42024Form from './components/clients/omni/ProspectingQ42024/elements/designForm.js'
import PreviewSheetListDesignRetargetingQ42024 from './components/elements/previewSheetLists/omni/previewSheetLists_RetargetingQ42024'
import PreviewSheetDesignRetargetingQ42024 from './components/elements/previewSheets/omni/previewSheet_RetargetingQ42024'
import DesignRetargetingQ42024Form from './components/clients/omni/RetargetingQ42024/elements/designForm'

import PreviewSheetListDesignAcme from './components/elements/previewSheetLists/acme/previewSheetLists_Acme'
import PreviewSheetDesignAcme from './components/elements/previewSheets/acme/previewSheet_Acme.js'
import DesignAcmeForm from './components/clients/acme/Acme_Banners/elements/designForm.js'
import PreviewSheetListsDesignTest from './components/elements/previewSheetLists/test/previewSheetLists_Test.js'
import PreviewSheetDesignTest from './components/elements/previewSheets/test/previewSheet_Test.js'
import DesignTestForm from './components/clients/test/Test_Banners/elements/designForm.js'
import TestBannerDesign from './components/clients/test/Designs'
import AcmeBannerDesign from './components/clients/acme/Designs'
import AcmePreviewSheetDesignList from '../src/components/elements/previewSheetLists/acme/previewSheetDesignList'
import TestPreviewSheetDesignList from '../src/components/elements/previewSheetLists/test/previewSheetDesignList'

import Design1Form from './components/clients/omni/design1/elements/design1Form.js'
import Design4Form from './components/clients/omni/design4/elements/design4Form.js'
import Design8Form from './components/clients/omni/design8_package/elements/design8_packageForm.js'
import Login from '../src/components/elements/Login.js'
import NavBar from '../src/components/elements/NavBar.js'
import Clients from '../src/components/elements/Clients'
import OmniDesign from '../src/components/clients/omni/Designs'
import SurveyMonkeyDesign from '../src/components/clients/surveymonkey/Designs'
import GenericDesign from '../src/components/clients/generic/Designs'
import NothingBundtCakesDesign from '../src/components/clients/nothingbundtcakes/Designs'
import TCSDesign from '../src/components/clients/thecontainerstore/Designs'
import PreviewSheetClients from '../src/components/elements/previewSheetClients';
import TCSPreviewSheetDesignList from '../src/components/elements/previewSheetLists/thecontainerstore/previewSheetDesignList'
import OmniPreviewSheetDesignList from '../src/components/elements/previewSheetLists/omni/previewSheetDesignList'
import SurveyMonkeyPreviewSheetDesignList from '../src/components/elements/previewSheetLists/surveymonkey/previewSheetDesignList'
import GenericPreviewSheetDesignList from '../src/components/elements/previewSheetLists/generic/previewSheetDesignList'
import NothingBundtCakesPreviewSheetDesignList from '../src/components/elements/previewSheetLists/nothingbundtcakes/previewSheetDesignList'

function App() {
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ userEmail, setUserEmail ] = useState("");
    const [ clients, setClients ] = useState("");

    useEffect(() => {
        axios.get('/checkAuth').then(res => {
            setLoggedIn(res.data.authenticated)
            setUserEmail(res.data.userEmail)
            setClients(res.data.clients)
        }).catch((error) => {
            setLoggedIn(false);
        })
    }, [])
    return(
        <div>
            {loggedIn && <NavBar email={userEmail}/>}

            <div className="container mt-3">
                <Routes>
                    <Route path="/" element={loggedIn ? <Clients clients={clients}/> : <Login/>} />
                    <Route path="/login-page" element={<Login/>} />
                    <Route path="/sheets" element={loggedIn ? <PreviewSheetClients clients={clients}/> : <Login/>} />

                    <Route path={"/omni"} element={loggedIn ? <OmniDesign clients={clients}/> : <Login/>} />
                    <Route path="/omni-preview-designs" element={loggedIn ? <OmniPreviewSheetDesignList clients={clients}/> : <Login/>}/>
                    <Route path="/designProspectingsheets/:id" element={<PreviewSheetDesignProspecting/>}/>
                    <Route path="/Prospecting" element={loggedIn ? <DesignProspectingForm/> : <Login/>}/>
                    <Route path="/Prospecting-previews" element={loggedIn ? <PreviewSheetListDesignProspecting/> : <Login/>}/>

                    <Route path="/designomni-design8sheets/:id" element={<PreviewSheetDesign8/>}/>
                    <Route path="/designomni-design1sheets/:id" element={<PreviewSheetDesign1/>}/>
                    <Route path="/designomni-design4sheets/:id" element={<PreviewSheetDesign4/>}/>
                    <Route path="/omni-design1" element={loggedIn ? <Design1Form/> : <Login/>}/>
                    <Route path="/omni-design4" element={loggedIn ? <Design4Form/> : <Login/>}/>
                    <Route path="/omni-design8" element={loggedIn ? <Design8Form/> : <Login/>}/>
                    <Route path="/omni-design1-previews" element={loggedIn ? <PreviewSheetListDesign1/> : <Login/>}/>
                    <Route path="/omni-design4-previews" element={loggedIn ? <PreviewSheetListDesign4/> : <Login/>}/>
                    <Route path="/omni-design8-previews" element={loggedIn ? <PreviewSheetListDesign8/> : <Login/>}/>

                    <Route path="/designRetargetingsheets/:id" element={<PreviewSheetDesignRetargeting/>}/>
                    <Route path="/Retargeting" element={loggedIn ? <DesignRetargetingForm/> : <Login/>}/>
                    <Route path="/Retargeting-previews" element={loggedIn ? <PreviewSheetListDesignRetargeting/> : <Login/>}/>

                    <Route path="/designProspectingQ42024sheets/:id" element={<PreviewSheetDesignProspectingQ42024/>}/>
                    <Route path="/ProspectingQ42024" element={loggedIn ? <DesignProspectingQ42024Form/> : <Login/>}/>
                    <Route path="/ProspectingQ42024-previews" element={loggedIn ? <PreviewSheetListDesignProspectingQ42024/> : <Login/>}/>

                    <Route path="/designRetargetingQ42024sheets/:id" element={<PreviewSheetDesignRetargetingQ42024 />}/>
                    <Route path="/RetargetingQ42024" element={loggedIn ? <DesignRetargetingQ42024Form/> : <Login/>}/>
                    <Route path="/RetargetingQ42024-previews" element={loggedIn ? <PreviewSheetListDesignRetargetingQ42024/> : <Login/>}/>

                    <Route path={"/SurveyMonkey"} element={loggedIn ? <SurveyMonkeyDesign clients={clients}/> : <Login/>} />
                    <Route path="/SurveyMonkey-preview-designs" element={loggedIn ? <SurveyMonkeyPreviewSheetDesignList clients={clients}/> : <Login/>}/>

                    <Route path="/designMomentive1sheets/:id" element={<PreviewSheetDesignMomentive1/>}/>
                    <Route path="/Momentive1" element={loggedIn ? <DesignMomentive1Form/> : <Login/>}/>
                    <Route path="/Momentive1-previews" element={loggedIn ? <PreviewSheetListDesignMomentive1/> : <Login/>}/>

                    <Route path={"/NothingBundtCakes"} element={loggedIn ? <NothingBundtCakesDesign clients={clients}/> : <Login/>} />
                    <Route path="/NothingBundtCakes-preview-designs" element={loggedIn ? <NothingBundtCakesPreviewSheetDesignList clients={clients}/> : <Login/>}/>

                    <Route path="/designNothingBundtCakesQ22023sheets/:id" element={<PreviewSheetDesignNothingBundtCakesQ22023/>}/>
                    <Route path="/NothingBundtCakesQ22023" element={loggedIn ? <DesignNothingBundtCakesQ22023Form/> : <Login/>}/>
                    <Route path="/NothingBundtCakesQ22023-previews" element={loggedIn ? <PreviewSheetListDesignNothingBundtCakesQ22023/> : <Login/>}/>

                    <Route path="/designSMDesign9sheets/:id" element={<PreviewSheetDesignSMDesign9/>}/>
                    <Route path="/SMDesign9" element={loggedIn ? <DesignSMDesign9Form/> : <Login/>}/>
                    <Route path="/SMDesign9-previews" element={loggedIn ? <PreviewSheetListDesignSMDesign9/> : <Login/>}/>

                    <Route path="/designSMDesign8sheets/:id" element={<PreviewSheetDesignSMDesign8/>}/>
                    <Route path="/SMDesign8" element={loggedIn ? <DesignSMDesign8Form/> : <Login/>}/>
                    <Route path="/SMDesign8-previews" element={loggedIn ? <PreviewSheetListDesignSMDesign8/> : <Login/>}/>

                    <Route path={"/TheContainerStore"} element={loggedIn ? <TCSDesign clients={clients}/> : <Login/>} />
                    <Route path="/TheContainerStore-preview-designs" element={loggedIn ? <TCSPreviewSheetDesignList clients={clients}/> : <Login/>}/>

                    <Route path="/designTCS2022sheets/:id" element={<PreviewSheetDesignTCS2022/>}/>
                    <Route path="/TCS2022" element={loggedIn ? <DesignTCS2022Form/> : <Login/>}/>
                    <Route path="/TCS2022-previews" element={loggedIn ? <PreviewSheetListDesignTCS2022/> : <Login/>}/>

                    <Route path={"/Generic"} element={loggedIn ? <GenericDesign clients={clients}/> : <Login/>} />
                    <Route path="/Generic-preview-designs" element={loggedIn ? <GenericPreviewSheetDesignList clients={clients}/> : <Login/>}/>

                    <Route path="/Generic-Banners" element={<DesignGenericBannersForm/>}/>
                    <Route path="/Generic-Banners-previews" element={<PreviewSheetListDesignGenericBanners/>}/>
                    <Route path="/designGeneric-BannersSheets/:id" element={<PreviewSheetDesignGenericBanners/>}/>

                    <Route path={"/Test"} element={loggedIn ? <TestBannerDesign clients={clients}/> : <Login/>} />
                    <Route path="/Test-preview-designs" element={loggedIn ? <TestPreviewSheetDesignList clients={clients}/> : <Login/>}/>

                    <Route path={"/Acme"} element={loggedIn ? <AcmeBannerDesign clients={clients}/> : <Login/>} />
                    <Route path="/Acme-preview-designs" element={loggedIn ? <AcmePreviewSheetDesignList clients={clients}/> : <Login/>}/>

                    <Route path="/TestDesign" element={<DesignTestForm/>}/>
                    <Route path="/Test-previews" element={<PreviewSheetListsDesignTest/>}/>
                    <Route path="/designTestDesignSheets/:id" element={<PreviewSheetDesignTest/>}/>

                    <Route path="/AcmeDesign" element={loggedIn ? <DesignAcmeForm/> : <Login/>}/>
                    <Route path="/Acme-previews" element={loggedIn ? <PreviewSheetListDesignAcme/> : <Login/>}/>
                    <Route path="/designAcmeDesignSheets/:id" element={<PreviewSheetDesignAcme/>}/>

                </Routes>
            </div>
        </div>
    )
}
export default App;
