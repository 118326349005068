import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                            ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');

        script.append(`
    Enabler.setProfileId(10923885);
    var devDynamicContent = {};

    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos = [{}];
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos[0]._id = 0;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos[0].Unique_ID = 1;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos[0].Hotel_Code = "OMNI";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos[0].Logo_Midnight = "${props.Logo_Midnight.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos[0].Logo_Cream = "${props.Logo_Cream.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting_Logos[0].Logo_Mobile = "${props.Logo_Mobile.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting = [{}];
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0]._id = 0;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Unique_ID = 1;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Reporting_Label = "2024_newwinter_OHR";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].IsApproved = false;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].IsDefault = true;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Campaign_Budget_Source = "Coporate";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].StartDate = {};
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].StartDate.RawValue = "11/11/2024";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].StartDate.UtcValue = 1731312000000;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].EndDate = {};
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].EndDate.RawValue = "1/4/2025";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].EndDate.UtcValue = 1735977600000;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Exit_URL = "${props.Exit_URL.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Headline = "${props.Headline}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Subheadline = "${props.Subheadline}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].CTA = "${props.CTA}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Image_Vertical = "${props.Image_Vertical.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Image_Vertical_Preview = "";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Image_Horizontal = "${props.Image_Horizontal.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Image_Horizontal_Preview = "";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Logo = "Logo Cream";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Logo_URL = "${props.Logo_URL.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_New_Retargeting[0].Hotel_Code_Match = "";
    Enabler.setDevDynamicContent(devDynamicContent);`)

        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
